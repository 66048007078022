import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import firebase from 'firebase.js';
import moment from 'moment';
import { numericFormatter } from 'react-number-format';
import { createComment } from 'state/actions/comments';
import { firebaseError, getApiUrlByHost, DEV_USERS } from 'utils';
import { getAuth } from "firebase/auth";
import { 
  writeBatch,
  getFirestore,
  doc,
  documentId,
} from "firebase/firestore"; 

import {
  fetchCollection,
  fetchDocument,
  updateDocument,
  getCountDocuments
} from '../api';

export const ORDERS_FETCH_DATA_INIT = createAction('ORDERS_FETCH_DATA_INIT');
export const ORDERS_FETCH_DATA_SUCCESS = createAction(
  'ORDERS_FETCH_DATA_SUCCESS'
);
export const ORDERS_FETCH_DATA_FAIL = createAction('ORDERS_FETCH_DATA_FAIL');

export const ORDERS_MODIFY_ORDER_INIT = createAction('ORDERS_MODIFY_ORDER_INIT');
export const ORDERS_PAY_ORDER_INIT = createAction('ORDERS_PAY_ORDER_INIT');

export const ORDERS_MODIFY_ORDER_SUCCESS = createAction(
  'ORDERS_MODIFY_ORDER_SUCCESS'
);

export const ORDERS_DELETE_ORDER_SUCCESS = createAction(
  'ORDERS_DELETE_ORDER_SUCCESS'
);

export const ORDERS_MODIFY_ORDER_FAIL = createAction('ORDERS_MODIFY_ORDER_FAIL');

export const ORDERS_CLEAN_UP = createAction('ORDERS_CLEAN_UP');

export const ORDERS_CLEAR_DATA_LOGOUT = createAction('ORDERS_CLEAR_DATA_LOGOUT');

export const sendEmail = async(id, route) => {
  try {
    const sendEmailHandle = firebase
      .functions()
      .httpsCallable('httpsSendEmail');

    await sendEmailHandle({id, route});
  }
  catch (error) {
    toastr.error('', 'Email not sent');
  }
};

export const fetchOrders = (orderId = '', newOrders = false) => {
  return async (dispatch, getState) => {
    dispatch(ORDERS_FETCH_DATA_INIT());

    const observeOrder = (updatedOrder) => {

      if(!updatedOrder){
        return dispatch(
          ORDERS_DELETE_ORDER_SUCCESS({
            id: orderId
          })
        );
      }

      return dispatch(
        ORDERS_MODIFY_ORDER_SUCCESS({
          id: orderId,
          order: updatedOrder,
        })
      );
    };

    if (orderId) {
      let order;

      try {
        order = await fetchDocument('orders', orderId, observeOrder);
      } catch (error) {
        toastr.error('', error);
        return dispatch(ORDERS_FETCH_DATA_FAIL({ error }));
      }

      if (!order) {
        const errorMessage = 'Order not available';
        toastr.error('', errorMessage);
        return dispatch(ORDERS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const orders = getState().orders.data;
      orders.push(order);

      return dispatch(
        ORDERS_FETCH_DATA_SUCCESS({
          data: orders,
        })
      );
    }
  
    const { role, additionalData } = getState().auth.userData;

    let orders;

    try {
      const queryOptions = {
        sort:[
          {
            'attribute': 'ServiceData.Source.ModDateTime',
            'order': 'desc'
          }
        ],
        queries: []
      };

      const date = moment().utc().subtract(1, 'month').format("YYYY-MM-DDTHH:mm:ss[Z]");

      if (newOrders) {
        queryOptions.queries.push({
          'attribute': 'ServiceData.Source.ModDateTime',
          'operator': '>=',
          'value': date
        });
      }

      if (role === 'Store') {
        const stores = getState().stores.data;
        const companiesIds = additionalData?.stores?.filter(storeID => 
          stores.find(store => store.id === storeID && store.integratorID))?.
          map(storeID => { 
            const currentStore = stores.find(store => store.id === storeID);

            return currentStore.integratorID;
          });

        if (!companiesIds || (companiesIds && companiesIds.length === 0)){
          orders = [];
        }
        else{
          queryOptions.queries.push({
            'attribute': 'CompaniesIds',
            'operator': 'array-contains-any',
            'value': companiesIds
          });

          orders = await fetchCollection('orders', queryOptions);
        }
      }
      else{
        orders = await fetchCollection('orders', queryOptions);
      }
    } catch (error) {
      toastr.error('', error);
      console.log(error);
      return dispatch(ORDERS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      ORDERS_FETCH_DATA_SUCCESS({
        data: orders,
      })
    );
  };
};

export const getOrderList = async({ limit = '', cursorId = '', direction = '', sort = null, subQueries = null, newOrders = false, observeCallback = null } = {}) => {
  const queries = [];
  let companiesIds = [];
  const auth = getAuth();
  
  await auth.authStateReady();
  const user = auth.currentUser;
  const userData = await fetchDocument('users', user.uid);

  if (!DEV_USERS.includes(userData.email)) {
    queries.push({
      attribute: 'StandardFields.OrderType',
      operator: '==',
      value: 'live'
    });
  }

  if (userData.role === 'Store') {
    const storeIds = userData?.additionalData?.stores;
    if (storeIds && storeIds.length > 0) {
      const storeQuery = {
        queries: [{
          'attribute': documentId(),
          'operator': 'in',
          'value': storeIds
        }]
      };

      const stores = await fetchCollection('stores', storeQuery);
      if (stores && stores.length > 0) {
        companiesIds = stores.map(el => el.integratorID);
        queries.push({
          'attribute': 'CompaniesIds',
          'operator': 'array-contains-any',
          'value': companiesIds 
        });
      }
    }
  }

  const date = moment().utc().subtract(1, 'month').format("YYYY-MM-DDTHH:mm:ss[Z]");

  if (newOrders) {
    queries.push({
      'attribute': 'ServiceData.Source.ModDateTime',
      'operator': '>=',
      'value': date
    });
  }

  const response = {
    data: [],
    rowCount: 0
  };

  const queryCountOptions = {
    queries
  };

  const queryOptions = {
    queries,
    sort:[
      {
        'attribute': 'ServiceData.Source.ModDateTime',
        'order': 'desc'
      }
    ],
  };

  const queryNextOptions = {
    queries,
    sort:[
      {
        'attribute': 'ServiceData.Source.ModDateTime',
        'order': 'desc'
      }
    ],
  };

  const queryPrevOptions = {
    queries,
    sort:[
      {
        'attribute': 'ServiceData.Source.ModDateTime',
        'order': 'desc'
      }
    ],
  };

  if (subQueries) {
    queryCountOptions.subQueries = subQueries;
    queryOptions.subQueries = subQueries;
    queryNextOptions.subQueries = subQueries;
    queryPrevOptions.subQueries = subQueries;
  }

  if (sort) {
    queryOptions.sort.push({sort});
    queryNextOptions.sort.push({sort});
    queryPrevOptions.sort.push({sort});
  }

  if (limit) {
    queryOptions.limit = limit;
    queryNextOptions.limit = limit;
    queryPrevOptions.limit = limit;
  }

  if (cursorId && direction) {
    queryOptions.direction = direction;
    queryOptions.cursorId = cursorId;
  }

  const observeOrders = (updatedOrders) => {
    if (observeCallback) {
      observeCallback(updatedOrders);
    }
  };

  try{
    if (userData.role !== 'Store' || (companiesIds && companiesIds.length)) {
      const count = await getCountDocuments('orders', queryCountOptions);
      response.rowCount = count;

      const orders = await fetchCollection('orders', queryOptions, observeOrders);
      response.data = orders;

      if (orders && orders.length && limit) {
        queryNextOptions.direction = 'next';
        queryNextOptions.cursorId = orders[orders.length - 1].id;

        const ordersNextX2 = await fetchCollection('orders', queryNextOptions);
        if (ordersNextX2 && (ordersNextX2.length === limit)) {
          response.cursorIdNextX2 = ordersNextX2[limit - 1].id;
        }

        queryPrevOptions.direction = 'prev';
        queryPrevOptions.cursorId = orders[0].id;

        const ordersPrevX2 = await fetchCollection('orders', queryPrevOptions);
        if (ordersPrevX2 && (ordersPrevX2.length === limit)) {
          response.cursorIdPrevX2 = ordersPrevX2[0].id;
        }
      }
    }
  }
  catch (error){
    console.log(error);
    toastr.error('', error);
  }

  return response;
};

export const setOrderType = async() => {
  const queryOptions = {
    queries: []
  };

  try {
    const orders = await fetchCollection('orders', queryOptions);

    const db = getFirestore();
    const batch = writeBatch(db);

    orders.forEach((item) => {
      const itemRef = doc(db, "orders", item.id);
      
      if(!item.StandardFields.OrderType){
        batch.update(itemRef, {
          "StandardFields.OrderType": 'live'
        });
      }
    });

    await batch.commit();
  } catch (error) {
    console.log(error);
    toastr.error('', error);
  }
};

export const clearOrdersDataLogout = () => {
  return (dispatch) => {
    dispatch(ORDERS_CLEAR_DATA_LOGOUT());
  };
};


export const modifyOrderNew = async({
  Billing,
  CompaniesIds,
  CustomFields,
  Items,
  Payments,
  Shipping,
  ShippingLine,
  StandardFields,
  ServiceData,
  StoreStatuses,
  Comments = [],
  id
}, showSuccessMsg = true) => {
  const orderData = {
    Billing,
    CompaniesIds,
    CustomFields,
    Items,
    Payments,
    Shipping,
    ShippingLine,
    StandardFields,
    ServiceData,
    StoreStatuses,
    Comments
  };
  
  orderData.StandardFields.LastUpdate = moment().utc().format();

  try {
    await updateDocument('orders', id, orderData);
    if (showSuccessMsg) {
      toastr.success('', 'Order updated successfully');
    }
  } catch (error) {
    toastr.error('', error.code);
  }
};

export const modifyOrder = ({
  Billing,
  CompaniesIds,
  CustomFields,
  Items,
  Payments,
  Shipping,
  ShippingLine,
  StandardFields,
  ServiceData,
  StoreStatuses,
  Comments = [],
  id
}, showSuccessMsg = true) => {
  return async (dispatch, getState) => {
    dispatch(ORDERS_MODIFY_ORDER_INIT());
    const { locale } = getState().preferences;

    const orderData = {
      Billing,
      CompaniesIds,
      CustomFields,
      Items,
      Payments,
      Shipping,
      ShippingLine,
      StandardFields,
      ServiceData,
      StoreStatuses,
      Comments
    };
    
    orderData.StandardFields.LastUpdate = moment().utc().format();

    const updateOrderDbTask = updateDocument('orders', id, orderData);

    try {
      await Promise.all([updateOrderDbTask]);
      if (showSuccessMsg) {
        toastr.success('', 'Order updated successfully');
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        ORDERS_MODIFY_ORDER_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(ORDERS_MODIFY_ORDER_SUCCESS({ order: orderData, id }));
  };
};

export const payOrderValitorPay = ({
  Billing,
  CompaniesIds,
  CustomFields,
  Items,
  Payments,
  Shipping,
  ShippingLine,
  StandardFields,
  ServiceData,
  StoreStatuses,
  Comments = [],
  id
}) => {
  return async (dispatch, getState) => {
    const { locale } = getState().preferences;
    const { userData } = getState().auth;

    const orderData = {
      Billing,
      CompaniesIds,
      CustomFields,
      Items,
      Payments,
      Shipping,
      ShippingLine,
      StandardFields,
      ServiceData,
      StoreStatuses,
      Comments
    };

    dispatch(ORDERS_PAY_ORDER_INIT({ order: orderData, id }));

    try {
      const orderValitorPay = firebase
        .functions()
        .httpsCallable('httpsPayOrder');

      await orderValitorPay({ id, capture: Payments[0].Capture });
      
      orderData.StandardFields.DeliveryStatus = 'ready';
      orderData.ServiceData.Source.Status = 'completed';
      orderData.Payments[0].PaymentFields.PaymentDate = moment().format();
      orderData.Payments[0].Note = JSON.stringify({
        TransactionId: orderData.Payments[0].PaymentFields.TransactionNumber,
        TransactionDate: orderData.Payments[0].PaymentFields.PaymentDate,
        TransactionStatus: 'Success'
      });

      orderData.StoreStatuses.forEach((storeItem, index) => {
        orderData.StoreStatuses[index].StoreStatus = 'ready';
      });

      const commentMsg = `Card Number Masked: ${orderData.Payments[0].PaymentFields.CardNumberMasked}
      Date: ${moment(orderData.Payments[0].PaymentFields.PaymentDate).utc().format("DD.MM.YYYY h:mma")}
      Authorization Number: ${orderData.Payments[0].PaymentFields.AuthorizationNumber}
      Transaction Number: ${orderData.Payments[0].PaymentFields.TransactionNumber}`;

      const commentData = {
        commentType: 'order',
        entityId: id,
        shortEntityId: orderData.ServiceData.Source.Identity,
        content: commentMsg,
        uid: userData.id,
        createdAt: moment().format()
      };

      const commentId = await createComment(commentData);
      
      if(!orderData.Comments) orderData.Comments = [];
      orderData.Comments.push(commentId);

      const isMissingAllProducts = orderData.Items.every(item => item.DeliveryStatus === 'missing');

      if (!(orderData.ShippingLine.ShipmentMethodCode === 'LOCAL_PICKUP' || isMissingAllProducts)) {
        try {
          const createOrderShipping = firebase
            .functions()
            .httpsCallable('httpsCreateOrderShipping');

          const orderShipping = await createOrderShipping(id);
          if (orderShipping.data && orderShipping.data.shipmentNumber) {
            orderData.ShippingLine.ShipmentNumber = orderShipping.data.shipmentNumber;

            const shipmentCommentMsg = `Shipment: ${orderData.ShippingLine.Description}
            Shipment Number: ${orderData.ShippingLine.ShipmentNumber}
            Shipment Method Code: ${orderData.ShippingLine.ShipmentMethodCode}`;

            const shipmentCommentData = {
              commentType: 'order',
              entityId: id,
              shortEntityId: orderData.ServiceData.Source.Identity,
              content: shipmentCommentMsg,
              uid: userData.id,
              createdAt: moment().format()
            };

            const shipmentCommentId = await createComment(shipmentCommentData);
            
            if(!orderData.Comments) orderData.Comments = [];
            orderData.Comments.push(shipmentCommentId);
          }
        }
        catch (error) {
          toastr.error('', 'Shipping is\'t created');
        }
      }

      const updateOrderDbTask = updateDocument('orders', id, orderData);
      await Promise.all([updateOrderDbTask]);

      try {
        await sendEmail(id, 'send-email-customer');
      }
      catch (error) {
        toastr.error('', 'Email to customer not sent');
      }

      try {
        const createOrderOnIntegrator = firebase
          .functions()
          .httpsCallable('httpsCreateOrderOnIntegrator');

        await createOrderOnIntegrator(id);
      }
      catch (error) {
        toastr.error('', 'Order not created on Integrator');
      }

    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      
      orderData.ServiceData.Source.Status = 'processing';
      const updateOrderDbTask = updateDocument('orders', id, orderData);

      await Promise.all([updateOrderDbTask]);

      return dispatch(ORDERS_MODIFY_ORDER_SUCCESS({ order: orderData, id }));
    }

    return dispatch(ORDERS_MODIFY_ORDER_SUCCESS({ order: orderData, id }));
  };
};

const delay = (time) => {
  return new Promise(resolve => setTimeout(resolve, time));
};

export const refundGiftCard = async(transactionId, storeId) => {
  let response = {};
  const apiUrl = getApiUrlByHost();

  try {
    const refundGiftCardHandle = firebase
      .functions()
      .httpsCallable('httpsRefundGiftCard');

    response = await refundGiftCardHandle({apiUrl, transactionId, storeId});
  }
  catch (error) {
    console.log(error);
    response = 'error';
  }

  return response;
};

export const releaseGiftCard = async(transactionId, storeId) => {
  let response = {};
  const apiUrl = getApiUrlByHost();

  try {
    const releaseGiftCardHandle = firebase
      .functions()
      .httpsCallable('httpsReleaseGiftCard');

    response = await releaseGiftCardHandle({apiUrl, transactionId, storeId});
  }
  catch (error) {
    console.log(error);
    response = 'error';
  }

  return response;
};

export const holdRedeemGiftCard = async(transactionId, redeemValue, storeId) => {
  let response = {};
  const apiUrl = getApiUrlByHost();

  try {
    const holdRedeemGiftCardHandle = firebase
      .functions()
      .httpsCallable('httpsHoldRedeemGiftCard');

    response = await holdRedeemGiftCardHandle({apiUrl, transactionId, redeemValue, storeId});
  }
  catch (error) {
    console.log(error);
    response = 'error';
  }

  return response;
};

export const getGiftCardTransactionList = async(giftCardNumber, storeId) => {
  let response = {};
  const apiUrl = getApiUrlByHost();

  try {
    const getGiftCardTransactionListHandle = firebase
      .functions()
      .httpsCallable('httpsGetGiftCardTransactionList');

    response = await getGiftCardTransactionListHandle({apiUrl, giftCardNumber, storeId});
  }
  catch (error) {
    console.log(error);
    response = 'error';
  }

  return response;
};

export const payOrderSaltPay = async({
  Billing,
  CompaniesIds,
  CustomFields,
  Items,
  Payments,
  Shipping,
  ShippingLine,
  StandardFields,
  ServiceData,
  StoreStatuses,
  Comments = [],
  id
}) => {
  const auth = getAuth();

  await auth.authStateReady();
  const user = auth.currentUser;
  const userData = await fetchDocument('users', user.uid);

  const orderData = {
    Billing,
    CompaniesIds,
    CustomFields,
    Items,
    Payments,
    Shipping,
    ShippingLine,
    StandardFields,
    ServiceData,
    StoreStatuses,
    Comments
  };

  orderData.StandardFields.processing = true;
  await updateDocument('orders', id, orderData); 
  try {
    const getTransactionInfo = firebase
      .functions()
      .httpsCallable('httpsGetTransactionInfoSaltPay');

    const orderSaltPay = firebase
      .functions()
      .httpsCallable('httpsPayOrderSaltPay');


    const isMissingAllProducts = Items.every(item => item.DeliveryStatus === 'missing' || item.DeliveryStatus === 'canceled' );
    const orderType = (orderData.StandardFields.OrderType && orderData.StandardFields.OrderType === 'dev') ? 'dev' : 'live';

    // processing for missing products
    const missingProducts = Items.filter(item => item.DeliveryStatus === 'missing' || item.DeliveryStatus === 'canceled');
    if (missingProducts && missingProducts.length > 0) {
      await Promise.all(missingProducts.map(async (missingProductItem) => {
        const giftCardPayments = orderData.Payments.filter(orderPaymentItem => orderPaymentItem.PaymentCode === 'giftcard' && orderPaymentItem.Store === missingProductItem.CompanyId && orderPaymentItem.PaymentFields.TransactionStatus === 'Hold');
        const creditCardPaymentIndex = orderData.Payments.findIndex(orderPaymentItem => orderPaymentItem.PaymentCode === 'saltpay-rpg' && orderPaymentItem.Store === missingProductItem.CompanyId && orderPaymentItem.PaymentFields.TransactionStatus === 'Uncaptured');
        let refundAmount = missingProductItem.Total;   

        if (giftCardPayments && giftCardPayments.length > 0) {
          await Promise.all(giftCardPayments.map(async (giftCardPayment) => {
            const giftCardPaymentIndex = orderData.Payments.findIndex(item => item.PaymentFields.TransactionNumber === giftCardPayment.PaymentFields.TransactionNumber);

            if (giftCardPaymentIndex >= 0 && refundAmount > 0) {
              refundAmount -= orderData.Payments[giftCardPaymentIndex].Capture;

              if (refundAmount >= 0) {
                orderData.Payments[giftCardPaymentIndex].Capture = 0;
              }
              else{
                orderData.Payments[giftCardPaymentIndex].Capture = Math.abs(refundAmount);
              }
            }
          }));
        }

        if(refundAmount > 0 && creditCardPaymentIndex >= 0) {
          orderData.Payments[creditCardPaymentIndex].Capture -= refundAmount;
        }
      }));
    }

    // process payment giftcard
    await Promise.all(orderData.Payments.map(async (payment, index) => {
      const paymentItem = {...payment};
      if (paymentItem.PaymentFields && paymentItem.PaymentFields.TransactionNumber && paymentItem.PaymentFields.TransactionStatus === 'Hold') {
        const transactionList = await getGiftCardTransactionList(paymentItem.GiftCard, paymentItem.Store);

        let transactionInfo = null;
        if (transactionList && transactionList.data.status === "success" && transactionList.data.result.transaction_list) {
          transactionInfo = transactionList.data.result.transaction_list.find(transactionItem => transactionItem.transaction_id === paymentItem.PaymentFields.TransactionNumber);
        }

        if (transactionInfo && transactionInfo.transaction_type === 'redeem') {
          orderData.Payments[index].PaymentFields.TransactionStatus = "Redeem";
          orderData.Payments[index].PaymentFields.PaymentDate = moment(transactionInfo.transaction_date).format();
          orderData.Payments[index].Note = JSON.stringify({
            TransactionId: orderData.Payments[index].PaymentFields.TransactionNumber,
            TransactionDate: orderData.Payments[index].PaymentFields.PaymentDate,
            TransactionStatus: orderData.Payments[index].PaymentFields.TransactionStatus
          });
        }
        else{
          const reference = JSON.parse(paymentItem.Reference);

          if (paymentItem.Store === 'Kringlan' && isMissingAllProducts){
            orderData.Payments[index].Capture = 0;
          }

          const refundGiftCardAmount = orderData.Payments[index].Amount - orderData.Payments[index].Capture;

          if (orderData.Payments[index].Capture === 0) {
            const releaseGiftCardResponse = await releaseGiftCard(orderData.Payments[index].PaymentFields.TransactionNumber, orderData.Payments[index].Store);
            if (releaseGiftCardResponse 
              && releaseGiftCardResponse.data 
              && releaseGiftCardResponse.data.status 
              && (releaseGiftCardResponse.data.status === 'success' 
                || releaseGiftCardResponse.data.result === "Hold balance has already been claimed."
                || releaseGiftCardResponse.data.result === "Hold amount is either released or expired.")) {
              const releaseTransactionId = releaseGiftCardResponse.data.status === 'success' ? releaseGiftCardResponse.data.result.transaction_id : orderData.Payments[index].PaymentFields.TransactionNumber;
              
              orderData.StandardFields.GrandTotal -= refundGiftCardAmount;
              orderData.StandardFields.Subtotal -= refundGiftCardAmount;

              orderData.Payments[index].PaymentFields.TransactionStatus = 'Release';
              orderData.Payments[index].PaymentFields.PaymentDate = moment().format();
              orderData.Payments[index].PaymentFields.TransactionNumber = releaseTransactionId;
              orderData.Payments[index].Note = JSON.stringify({
                TransactionId: releaseTransactionId,
                TransactionDate: orderData.Payments[index].PaymentFields.PaymentDate,
                TransactionStatus: orderData.Payments[index].PaymentFields.TransactionStatus
              });

              const paymentAmountFormat = numericFormatter(refundGiftCardAmount.toString(), {thousandSeparator: true, suffix: 'kr'});

              const commentMsg = `Store: ${reference?.storeName}
                Date: ${moment(orderData.Payments[index].PaymentFields.PaymentDate).utc().format("DD.MM.YYYY h:mma")}
                Transaction Status: ${orderData.Payments[index].PaymentFields.TransactionStatus}
                Transaction Number: ${orderData.Payments[index].PaymentFields.TransactionNumber}
                Transaction Type: Release gift card
                Amount: ${paymentAmountFormat}`;

              const commentData = {
                commentType: 'order',
                entityId: id,
                shortEntityId: orderData.ServiceData.Source.Identity,
                content: commentMsg,
                uid: userData.id,
                createdAt: moment().format()
              };

              const commentId = await createComment(commentData);
              
              if(!orderData.Comments) orderData.Comments = [];
              if(!orderData.Payments[index].CommentIds) orderData.Payments[index].CommentIds = [];

              orderData.Comments.push(commentId);
              orderData.Payments[index].CommentIds.push(commentId);
            }
            else{
              orderData.Payments[index].Capture = orderData.Payments[index].Amount;
              throw new Error("gift-card/payment-error");
            }
          }
          else{
            const holdRedeemGiftCardResponse = await holdRedeemGiftCard(orderData.Payments[index].PaymentFields.TransactionNumber, orderData.Payments[index].Capture, orderData.Payments[index].Store);
            if (holdRedeemGiftCardResponse && holdRedeemGiftCardResponse.data && holdRedeemGiftCardResponse.data.status && (holdRedeemGiftCardResponse.data.status === 'success' || holdRedeemGiftCardResponse.data.result === "Hold balance has already been claimed.")) {
              const redeemTransactionId = holdRedeemGiftCardResponse.data.status === 'success' ? holdRedeemGiftCardResponse.data.result.transaction_id : orderData.Payments[index].PaymentFields.TransactionNumber;
              
              orderData.StandardFields.GrandTotal -= refundGiftCardAmount;
              orderData.StandardFields.Subtotal -= refundGiftCardAmount;

              orderData.Payments[index].PaymentFields.TransactionStatus = 'Redeem';
              orderData.Payments[index].PaymentFields.PaymentDate = moment().format();
              orderData.Payments[index].PaymentFields.TransactionNumber = redeemTransactionId;
              orderData.Payments[index].Note = JSON.stringify({
                TransactionId: redeemTransactionId,
                TransactionDate: orderData.Payments[index].PaymentFields.PaymentDate,
                TransactionStatus: orderData.Payments[index].PaymentFields.TransactionStatus
              });

              const paymentAmountFormat = numericFormatter(orderData.Payments[index].Capture.toString(), {thousandSeparator: true, suffix: 'kr'});

              const commentMsg = `Store: ${reference?.storeName}
                Date: ${moment(orderData.Payments[index].PaymentFields.PaymentDate).utc().format("DD.MM.YYYY h:mma")}
                Transaction Status: ${orderData.Payments[index].PaymentFields.TransactionStatus}
                Transaction Number: ${orderData.Payments[index].PaymentFields.TransactionNumber}
                Transaction Type: Redeem gift card
                Amount: ${paymentAmountFormat}`;

              const commentData = {
                commentType: 'order',
                entityId: id,
                shortEntityId: orderData.ServiceData.Source.Identity,
                content: commentMsg,
                uid: userData.id,
                createdAt: moment().format()
              };

              const commentId = await createComment(commentData);
              
              if(!orderData.Comments) orderData.Comments = [];
              if(!orderData.Payments[index].CommentIds) orderData.Payments[index].CommentIds = [];

              orderData.Comments.push(commentId);
              orderData.Payments[index].CommentIds.push(commentId);

              if (orderData.Payments[index].Capture !== orderData.Payments[index].Amount) {
                const refundAmountFormat = numericFormatter(refundGiftCardAmount.toString(), {thousandSeparator: true, suffix: 'kr'});

                const commentRefundMsg = `Store: ${reference?.storeName}
                  Date: ${moment(orderData.Payments[index].PaymentFields.PaymentDate).utc().format("DD.MM.YYYY h:mma")}
                  Transaction Status: Refunded
                  Transaction Number: ${orderData.Payments[index].PaymentFields.TransactionNumber}
                  Transaction Type: Refund gift card
                  Amount: ${refundAmountFormat}`;

                const commentRefundData = {
                  commentType: 'order',
                  entityId: id,
                  shortEntityId: orderData.ServiceData.Source.Identity,
                  content: commentRefundMsg,
                  uid: userData.id,
                  createdAt: moment().format()
                };

                const commentRefundId = await createComment(commentRefundData);
                
                if(!orderData.Comments) orderData.Comments = [];
                if(!orderData.Payments[index].CommentIds) orderData.Payments[index].CommentIds = [];

                orderData.Comments.push(commentRefundId);
                orderData.Payments[index].CommentIds.push(commentRefundId);
              }
            }
            else{
              orderData.Payments[index].Capture = orderData.Payments[index].Amount;
              throw new Error("gift-card/payment-error");
            }
          }
        }
        await delay(1000);
        await updateDocument('orders', id, orderData);   
      }
    }));

    // process payment saltpay
    await Promise.all(orderData.Payments.map(async (payment, index) => {
      const paymentItem = {...payment};

      if (paymentItem.PaymentFields && paymentItem.PaymentFields.TransactionNumber && paymentItem.PaymentFields.TransactionStatus === 'Uncaptured') {
        const transactionInfo = await getTransactionInfo({payment: paymentItem, orderType});

        if (transactionInfo && transactionInfo.data && transactionInfo.data.TransactionStatus !== 'Uncaptured') {
          orderData.Payments[index].PaymentFields.TransactionStatus = transactionInfo.data.TransactionStatus;
          orderData.Payments[index].PaymentFields.PaymentDate = moment().format();
          orderData.Payments[index].Note = JSON.stringify({
            TransactionId: orderData.Payments[index].PaymentFields.TransactionNumber,
            TransactionDate: orderData.Payments[index].PaymentFields.PaymentDate,
            TransactionStatus: transactionInfo.data.TransactionStatus
          });
        }
        else{
          const reference = JSON.parse(paymentItem.Reference);

          let action = 'capture';
          if (paymentItem.Capture === 0)
            action = 'cancel';

          if (paymentItem.Store === 'Kringlan' && isMissingAllProducts){
            action = 'cancel';
            orderData.Payments[index].Capture = 0;
          }

          const saltPayResponse = await orderSaltPay({payment: paymentItem, orderType, action});
          if (saltPayResponse && saltPayResponse.data === 'success') {
            let TransactionStatus = 'Captured';
            if(action === 'cancel'){
              const canceledAmount = orderData.Payments[index].Amount - orderData.Payments[index].Capture;
              TransactionStatus = 'Canceled';
              orderData.StandardFields.GrandTotal -= canceledAmount;
              orderData.StandardFields.Subtotal -= canceledAmount;
            }

            orderData.Payments[index].PaymentFields.TransactionStatus = TransactionStatus;
            orderData.Payments[index].PaymentFields.PaymentDate = moment().format();
            orderData.Payments[index].Note = JSON.stringify({
              TransactionId: orderData.Payments[index].PaymentFields.TransactionNumber,
              TransactionDate: orderData.Payments[index].PaymentFields.PaymentDate,
              TransactionStatus
            });

            const paymentAmountFormat = numericFormatter(paymentItem.Amount.toString(), {thousandSeparator: true, suffix: 'kr'});

            const commentMsg = `Store: ${reference?.storeName}
              Card Number Masked: ${orderData.Payments[index].PaymentFields.CardNumberMasked}
              Date: ${moment(orderData.Payments[index].PaymentFields.PaymentDate).utc().format("DD.MM.YYYY h:mma")}
              Transaction Status: ${orderData.Payments[index].PaymentFields.TransactionStatus}
              Transaction Number: ${orderData.Payments[index].PaymentFields.TransactionNumber}
              Transaction Type: ${action}
              Amount: ${paymentAmountFormat}`;

            const commentData = {
              commentType: 'order',
              entityId: id,
              shortEntityId: orderData.ServiceData.Source.Identity,
              content: commentMsg,
              uid: userData.id,
              createdAt: moment().format()
            };

            const commentId = await createComment(commentData);
            
            if(!orderData.Comments) orderData.Comments = [];
            if(!orderData.Payments[index].CommentIds) orderData.Payments[index].CommentIds = [];

            orderData.Comments.push(commentId);
            orderData.Payments[index].CommentIds.push(commentId);

            if (action === 'capture' && paymentItem.Capture !== paymentItem.Amount) {
              const saltPayRefundResponse = await orderSaltPay({payment: paymentItem, orderType, action: 'refund'});
              if (saltPayRefundResponse && saltPayRefundResponse.data && saltPayRefundResponse.data.RefundTransactionId) {
                const refundAmount = paymentItem.Amount - paymentItem.Capture;
                orderData.StandardFields.GrandTotal -= refundAmount;
                orderData.StandardFields.Subtotal -= refundAmount;

                const refundAmountFormat = numericFormatter(refundAmount.toString(), {thousandSeparator: true, suffix: 'kr'});

                const commentRefundMsg = `Store: ${reference?.storeName}
                  Card Number Masked: ${orderData.Payments[index].PaymentFields.CardNumberMasked}
                  Date: ${moment().utc().format("DD.MM.YYYY h:mma")}
                  Transaction Status: Refunded
                  Transaction Number: ${saltPayRefundResponse.data.RefundTransactionId}
                  Transaction Type: Refund
                  Amount: ${refundAmountFormat}`;

                const commentRefundData = {
                  commentType: 'order',
                  entityId: id,
                  shortEntityId: orderData.ServiceData.Source.Identity,
                  content: commentRefundMsg,
                  uid: userData.id,
                  createdAt: moment().format()
                };

                orderData.Payments[index].PaymentFields.TransactionStatus = "Refunded";
                orderData.Payments[index].PaymentFields.PaymentDate = moment().format();
                orderData.Payments[index].Note = JSON.stringify({
                  TransactionId: saltPayRefundResponse.data.RefundTransactionId,
                  TransactionDate: orderData.Payments[index].PaymentFields.PaymentDate,
                  TransactionStatus: "Refunded"
                });

                const commentRefundId = await createComment(commentRefundData);
                
                if(!orderData.Comments) orderData.Comments = [];
                if(!orderData.Payments[index].CommentIds) orderData.Payments[index].CommentIds = [];

                orderData.Comments.push(commentRefundId);
                orderData.Payments[index].CommentIds.push(commentRefundId);
              }
              else{
                orderData.Payments[index].Capture = orderData.Payments[index].Amount;
                throw new Error("salt-pay/payment-error");
              }
            }
          }
          else{
            orderData.Payments[index].Capture = orderData.Payments[index].Amount;
            throw new Error("salt-pay/payment-error");
          }
        }
        await delay(1000);
        await updateDocument('orders', id, orderData);   
      }
    }));
  
    orderData.StandardFields.DeliveryStatus = 'ready';
    orderData.ServiceData.Source.Status = 'completed';

    StoreStatuses.forEach((storeItem, index) => {
      orderData.StoreStatuses[index].StoreStatus = 'ready';
    });

    if (!(orderData.ShippingLine.ShipmentMethodCode === 'LOCAL_PICKUP' || isMissingAllProducts)) {
      try {
        if (!(orderData.ShippingLine && orderData.ShippingLine.ShipmentNumber)) {

          const createOrderShipping = firebase
            .functions()
            .httpsCallable('httpsCreateOrderShipping');

          const orderShipping = await createOrderShipping(id);
          if (orderShipping.data && orderShipping.data.shipmentNumber) {
            orderData.ShippingLine.ShipmentNumber = orderShipping.data.shipmentNumber;

            const shipmentCommentMsg = `Shipment: ${orderData.ShippingLine.Description}
            Shipment Number: ${orderData.ShippingLine.ShipmentNumber}
            Shipment Method Code: ${orderData.ShippingLine.ShipmentMethodCode}`;

            const shipmentCommentData = {
              commentType: 'order',
              entityId: id,
              shortEntityId: orderData.ServiceData.Source.Identity,
              content: shipmentCommentMsg,
              uid: userData.id,
              createdAt: moment().format()
            };

            const shipmentCommentId = await createComment(shipmentCommentData);
            
            if(!orderData.Comments) orderData.Comments = [];
            orderData.Comments.push(shipmentCommentId);

            await delay(1000);
            await updateDocument('orders', id, orderData);
          }
        }
      }
      catch (error) {
        toastr.error('', 'Shipping is\'t created');
      }
    }

    orderData.StandardFields.ExportOrderOfTimes = 0;
    orderData.StandardFields.ExportOrderStatus = isMissingAllProducts ? 'cancelled' : 'pending';
    orderData.StandardFields.ExportOrderDate = moment().format();

    await delay(1000);
    await updateDocument('orders', id, orderData);

    try {
      if (!isMissingAllProducts) {
        await sendEmail(id, 'send-email-customer');
      }
    }
    catch (error) {
      toastr.error('', 'Email to customer not sent');
    }

    try {
      if (!isMissingAllProducts) {
        const createOrderOnIntegrator = firebase
          .functions()
          .httpsCallable('httpsCreateOrderOnIntegrator');

        await createOrderOnIntegrator({id, fullExport: false});
        orderData.StandardFields.ExportOrderStatus = 'success';
      }
    }
    catch (error) {
      toastr.error('', 'Order not created on Integrator');
      orderData.StandardFields.ExportOrderStatus = 'failed';
    }

    orderData.StandardFields.processing = false;
    
    const changedStatusCommentData = {
      commentType: 'order',
      entityId: id,
      shortEntityId: orderData.ServiceData.Source.Identity,
      content: 'Order status changed from Received by service desk to Ready',
      uid: userData.id,
      createdAt: moment().format()
    };

    const changedStatusCommentId = await createComment(changedStatusCommentData);
    
    if(!orderData.Comments) orderData.Comments = [];
    orderData.Comments.push(changedStatusCommentId);

    if (isMissingAllProducts) {
      orderData.StandardFields.DeliveryStatus = 'cancelled';

      StoreStatuses.forEach((storeItem, index) => {
        orderData.StoreStatuses[index].StoreStatus = 'cancelled';
      });
      
      changedStatusCommentData.content = 'Order status changed from Ready to Cancelled';
      changedStatusCommentData.createdAt = moment().format();

      const cancelledCommentId = await createComment(changedStatusCommentData);
      
      if(!orderData.Comments) orderData.Comments = [];
      orderData.Comments.push(cancelledCommentId);
    }

    await delay(1000);
    await updateDocument('orders', id, orderData);

  } catch (error) {
    const errorMessage = firebaseError(error.code || error.message, 'is');
    toastr.error('', errorMessage);
    
    orderData.StandardFields.processing = false;
    orderData.ServiceData.Source.Status = 'processing';
    await updateDocument('orders', id, orderData);
  }
};

export const ordersCleanUp = () => (dispatch) => dispatch(ORDERS_CLEAN_UP());

export const getShipmentLabel = async(shipmentId) => {
  let response;

  try {
    const getShipmentInfoHandler = firebase
      .functions()
      .httpsCallable('httpsGetShipmentInfo');

    const urlParam = `labels/${shipmentId}?labelType=PDF`;
    response = await getShipmentInfoHandler(urlParam);
  }
  catch (error) {
    toastr.error('', 'Shipment Label Error');
  };

  return response;
};

export const getShipmentStatus = async(shipmentId) => {
  let response;

  try {
    const getShipmentInfoHandler = firebase
      .functions()
      .httpsCallable('httpsGetShipmentInfo');

    const urlParam = `shipments/status/${shipmentId}`;
    response = await getShipmentInfoHandler(urlParam);
  }
  catch (error) {
    toastr.error('', 'Shipment Label Error');
  };

  return response;
};

export const exportOrder = ({id, fullExport = true}) => {
  return async (dispatch, getState) => {
    const orderData = getState().orders.data.find(item => item.id === id);
    orderData.StandardFields.ExportOrderOfTimes = 0;
    orderData.StandardFields.ExportOrderStatus = 'pending';
    orderData.StandardFields.ExportOrderDate = moment().format();

    const isMissingAllProducts = orderData.Items.every(item => ["missing", "canceled"].includes(item.DeliveryStatus));

    try {
      if (!isMissingAllProducts) {
        const createOrderOnIntegrator = firebase
          .functions()
          .httpsCallable('httpsCreateOrderOnIntegrator');

        await createOrderOnIntegrator({id, fullExport});
        orderData.StandardFields.ExportOrderStatus = 'success';
        toastr.success('', 'Order created on Integrator');
      }
      else{
        orderData.StandardFields.ExportOrderStatus = 'canceled';
        toastr.error('', 'Order not created on Integrator - all products are missing');
      }
      await updateDocument('orders', id, orderData);
    }
    catch (error) {
      toastr.error('', 'Order not created on Integrator');
      orderData.StandardFields.ExportOrderStatus = 'failed';

      await updateDocument('orders', id, orderData);
    }
    
    return dispatch(ORDERS_MODIFY_ORDER_SUCCESS({ order: orderData, id }));

  };
};

export const exportOrderForStores = (orderData, sourceStatus, targetStatus) => {
  return async (dispatch, getState) => {
    const { locale } = getState().preferences;
    const newOrderData = {...orderData};
    const { id, StoreStatuses, Items } = newOrderData;
    let exportError = false;

    try {
      newOrderData.StandardFields.DeliveryStatus = targetStatus;

      await updateDocument('orders', id, {
        'StandardFields.processing': true,
        'StandardFields.DeliveryStatus': targetStatus
      });

      await Promise.all(StoreStatuses.map(async (storeItem, index) => {
        const storeData = getState().stores.data.find(item => item.ordersCompanyID && item.ordersCompanyID === storeItem.CompanyId);

        if (storeData && storeData.orderExport && storeData.orderExport === true && 
        (storeData.orderExportStatus === targetStatus || (sourceStatus === 'todo' && storeData.orderExportStatus === 'todo')) && 
        (!storeItem.OrderExportStatus || (storeItem.OrderExportStatus && ["pending", "failed"].includes(storeItem.OrderExportStatus)))) 
        {
          const isMissingAllProductsForStore = Items.filter(item => item.CompanyId === storeItem.CompanyId).every(item => ["missing", "canceled"].includes(item.DeliveryStatus));
          
          if (!isMissingAllProductsForStore) {
            const httpsCreateOrderOnIntegratorForStore = firebase
              .functions()
              .httpsCallable('httpsCreateOrderOnIntegratorForStore');

            const createOrderOnIntegratorResponse = await httpsCreateOrderOnIntegratorForStore({orderId: id, storeId: storeItem.CompanyId});
            if (createOrderOnIntegratorResponse.data && createOrderOnIntegratorResponse.data === "success") {
              newOrderData.StoreStatuses[index].OrderExportStatus = 'success';
            }
            else{
              newOrderData.StoreStatuses[index].OrderExportStatus = 'failed';
              throw new Error("order/export-order-integrator");
            }
          }
          else{
            newOrderData.StoreStatuses[index].OrderExportStatus = 'cancelled';
          }
        }
      }));
    } catch (error) {
      const errorMessage = firebaseError(error.code || error.message, locale);
      toastr.error('', errorMessage);
      exportError = true;

      let oldDeliveryStatus = 'todo';
      if (targetStatus === 'received') {
        oldDeliveryStatus = 'dispatched';
      }
      if (targetStatus === 'dispatched') {
        oldDeliveryStatus = 'in_picking';
      }

      newOrderData.StandardFields.DeliveryStatus = oldDeliveryStatus;
    } finally {
      newOrderData.StandardFields.processing = false;
      await updateDocument('orders', id, newOrderData);
    }

    return dispatch(ORDERS_MODIFY_ORDER_SUCCESS({ order: newOrderData, id, error: exportError}));
  };
};