/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ClipLoader from 'react-spinners/ClipLoader';
import OrderItems from 'components/OrderItems';
import { NumericFormat } from 'react-number-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import classNames from 'classnames';
import moment from 'moment';
import { checkAccess } from 'permissions';
import { modifyOrder, ordersCleanUp, getShipmentLabel, getShipmentStatus, exportOrder, sendEmail } from 'state/actions/orders';
import { deleteComment, fetchComments, createComment } from 'state/actions/comments';
import { fetchUsers, usersCleanUp } from 'state/actions/users';
import { fetchStores, storesCleanUp } from 'state/actions/stores';
import { useFormatMessage } from 'hooks';
import { ORDER_STATUS } from 'utils';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import logo from 'assets/logo.svg';

import './OrderForm.scss';

const OrderForm = ({ order, onSubmitHandler }) => {
  const { user, users, loading, success, stores } = useSelector(
    (state) => ({
      user: state.auth.userData,
      loading: state.orders.loading,
      success: state.orders.success,
      users: state.users.data,
      stores: state.stores.data
    }),
    shallowEqual
  );

  const {id: orderID, StandardFields: { Note: orderNotes, DeliveryStatus}, ShippingLine: { ShipmentMethodCode }} = order;

  const stickersPrintComponentRef = useRef();
  const receiptPrintComponentRef = useRef();
  const shipmentLabelPrintComponentRef = useRef();
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const commentsBlock = useRef(null);
  const { hash } = useLocation();
  const [commentMsg, setCommentMsg] = useState([]);
  const [shipmentLabelData, setShipmentLabelData] = useState('');
  const [shipmentStatusData, setShipmentStatusData] = useState();
  const [loadedShipment, setLoadedShipment] = useState(false);
  const [loadingExportOrder, setLoadingExportOrder] = useState(false);
  const [loadingSendReminder, setLoadingSendReminder] = useState(false);
  const [commentsData, setCommentsData] = useState();
  const [commentsDataLoaded, setCommentsDataLoaded] = useState(false);
  const [commentsDataChanging, setCommentsDataChanging] = useState(false);

  const onChangeCommentMsg = () => {
    setCommentMsg(textareaRef.current.value);
  };

  const handleAddComment = async() => {
    if(commentMsg){
      const commentData = {
        commentType: 'order',
        entityId: orderID,
        shortEntityId: order.ServiceData.Source.Identity,
        content: commentMsg,
        uid: user.id,
        createdAt: moment().format()
      };

      const newOrder = {
        ...order
      };

      setCommentsDataChanging(true);

      try{
        const commentId  = await createComment(commentData);
        setCommentMsg('');
        const updatedCommentsData = await fetchComments({entityId: orderID});
        setCommentsData(updatedCommentsData);

        if(!newOrder.Comments) newOrder.Comments = [];

        newOrder.Comments.push(commentId);
        dispatch(modifyOrder(newOrder));
      }
      finally{
        setCommentsDataChanging(false);
      }
    }
  };

  useEffect(() => {
    let isSubscribed = true;

    const fetchCommentsData = async () => {
      const getCommentsResponse = await fetchComments({entityId: orderID});
      if (isSubscribed) {
        setCommentsData(getCommentsResponse);
        setCommentsDataLoaded(true);
      }
    };

    fetchCommentsData()
      .catch(console.error);

    return () => { isSubscribed = false; };
  }, []);

  const orderComments = commentsData 
    ? commentsData.map(comment => {
      const commentClone = {... comment};
      
      let authorComment = null;
      authorComment = users.find(userItem => userItem.id === comment.uid);

      if(authorComment){
        commentClone.authorName = authorComment.name;
        commentClone.primary = false;
        commentClone.email = authorComment?.email;
      }
      else if(user.id === comment.uid){
        commentClone.authorName = user.name;
        commentClone.primary = true;
        commentClone.email = user?.email;
      }

      return commentClone;
    }) 
    : [];

  const scrollIntoViewWithOffset = (el, offset) => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        el.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    });
  };

  useEffect(() => {    
    if(commentsBlock.current){
      const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          scrollIntoViewWithOffset(element, 20);
        }
    }
  }, [commentsBlock.current]); 

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { ...order },
  });

  useEffect(() => {
    return () => dispatch(ordersCleanUp());
  }, [dispatch, success, setValue]);

  useEffect(() => {
    const fetchShipmentData = async (shipmentNumber) => {
      const shipmentLabelResponse = await getShipmentLabel(shipmentNumber);

      if(shipmentLabelResponse.data.label){
        setShipmentLabelData(shipmentLabelResponse.data.label);
      }

      const shipmentStatusResponse = await getShipmentStatus(shipmentNumber);
      if(shipmentStatusResponse.data.shipmentStatus){
        setShipmentStatusData(shipmentStatusResponse.data.shipmentStatus);
      }

      setLoadedShipment(true);
    };

    if(order.ShippingLine.ShipmentNumber){
      fetchShipmentData(order.ShippingLine.ShipmentNumber);
    }

  }, []);

  const handleSaveShipmentLabelPDF = () => {
    if(loadedShipment){
      const linkSource = `data:application/pdf;base64,${shipmentLabelData}`;
      const downloadLink = document.createElement("a");
      const fileName = "Shipment Label.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => shipmentLabelPrintComponentRef.current,
    bodyClass: 'shipment-label-component'
  });

  useEffect(() => {
    if(!users.length)
      dispatch(fetchUsers());
    
    dispatch(fetchStores());
    
    return () => {
      dispatch(usersCleanUp());
      dispatch(storesCleanUp());
    };

  }, [dispatch]);

  const AccessToEditOrder = checkAccess('edit order');
  const accessToViewComments = checkAccess("view order comments");
  const accessToDeleteAnyComments = checkAccess("delete any comment");
  const accessToDeleteOwnComments = checkAccess("delete own comment");
  const AccessToExportOrder = checkAccess('export order');

  const printReceiptLabel = useFormatMessage('OrderForm.printReceipt');
  const printStickersLabel = useFormatMessage('OrderForm.printStickers');
  const saveShipmentLabelPDF = useFormatMessage('OrderForm.saveShipmentLabelPDF');
  const printShipmentLabelPDF = useFormatMessage('OrderForm.printShipmentLabelPDF');
  const exportOrderLabel = useFormatMessage('OrderForm.exportOrderLabel');
  const deliveryReminderLabel = useFormatMessage('OrderForm.deliveryReminderLabel');
  const commentsTitleSection = useFormatMessage('OrderForm.comments');
  const generalLabel = useFormatMessage('OrderForm.general');
  const dateCreatedLabel = useFormatMessage('OrderForm.DateCreated');
  const dateModifiedLabel = useFormatMessage('OrderForm.DateModified');
  const ExportOrderStatusLabel = useFormatMessage('OrderForm.ExportOrderStatus');
  const ExportOrderDateLabel = useFormatMessage('OrderForm.ExportOrderDate');

  const paymentStatusLabel = useFormatMessage('OrderForm.paymentStatus');
  const deliveryStatusLabel = useFormatMessage('OrderForm.deliveryStatus');
  const billingInfoLabel = useFormatMessage('OrderForm.billingInfo');
  const shippingInfoLabel = useFormatMessage('OrderForm.shippingInfo');
  const TrackingNoLabel = useFormatMessage('OrderForm.TrackingNo');
  const DeliveryConfirmedDateLabel = useFormatMessage('OrderForm.DeliveryConfirmedDate');
  const orderItemsLabel = useFormatMessage('OrderForm.orderItems');
  const notesLabel = useFormatMessage('OrderForm.notes');
  const orderSummaryLabel = useFormatMessage('OrderForm.orderSummary');
  const subtotalLabel = useFormatMessage('OrderForm.subtotal');
  const taxLabel = useFormatMessage('OrderForm.tax');
  const TotalLabel = useFormatMessage('OrderForm.Total');

  const onDeleteComment = async(commentId) => {  
    const commentsUpdated = order.Comments.filter((commentItem) => commentItem !== commentId);

    const newOrder = {
      ...order,
      Comments: commentsUpdated
    };

    try{
      setCommentsDataChanging(true);

      await deleteComment(commentId);
      const updatedCommentsData = await fetchComments({entityId: orderID});
      setCommentsData(updatedCommentsData);
      dispatch(modifyOrder(newOrder));
    }
    finally{
      setCommentsDataChanging(false);
    }
  };

  const getPageReceiptSize = () => {
    return `@page { size: 8cm 14.8cm !important; }`;
  };

  const getPageStickersSize = () => {
    return `@page { size: 10.5cm 14.8cm !important; }`;
  };

  const groupByStore = (products) => {
    return products.reduce((result, currentValue) => {
      const resultItem = result;

      const group = (resultItem[currentValue.StoreName] || []);
      group.push(currentValue);
      resultItem[currentValue.StoreName] = group;
      return resultItem;

    }, {}); 
  };

  const handleExportOrder = () => {
    setLoadingExportOrder(true);
    dispatch(exportOrder({id: orderID})).then(() => setLoadingExportOrder(false));
  };

  const handleSendReminder = () => {
    setLoadingSendReminder(true);

    const newOrder = {...order};
    newOrder.StandardFields.sendEmailReminder = true;
    
    sendEmail(orderID, 'send-reminder-email-customer').then(() => { 
      dispatch(modifyOrder(newOrder)).then(() => setLoadingSendReminder(false));
    });
  };

  const userStores = user.role === 'Store' ? stores.filter(item => user.additionalData.stores.includes(item.id)) : null;

  const orderData = order.Items.filter(item => {
    let result = true;
    if(userStores && userStores.length > 0){
      result = userStores.some(storeItem => storeItem.integratorID === item.CompanyId);
    }

    return result;
  });

  const totalForStore = orderData.reduce((total, item) => total + item.Total, 0);
  const groupItems = groupByStore(orderData);

  return (
    <>
      <div ref={stickersPrintComponentRef} className="columns is-desktop">
        <style>{getPageStickersSize()}</style>
        <div className={classNames('column',
          {'is-9-desktop': accessToViewComments})}>
          <div className="columns">
            <div id="general-column" className="column">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-bell default" />
                    </span>
                    {generalLabel}
                  </p>
                </header>
                <div className="card-content">
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="field is-horizontal is-align-items-center">
                      <div className="field-label is-normal">
                        <label className="label">
                          {dateCreatedLabel}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            { moment(order.ServiceData.Source.CreateDateTime).utc().format("DD.MM.YYYY HH:mm") }                          
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal is-align-items-center">
                      <div className="field-label is-normal">
                        <label className="label">
                          { dateModifiedLabel }
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            { moment(order.ServiceData.Source.ModDateTime).utc().format("DD.MM.YYYY HH:mm") }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal is-align-items-center field-print-hide">
                      <div className="field-label is-normal">
                        <label className="label">
                          { ExportOrderStatusLabel }
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            { order.StandardFields.ExportOrderStatus }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal is-align-items-center field-print-hide">
                      <div className="field-label is-normal">
                        <label className="label">
                          { ExportOrderDateLabel }
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                          { order.StandardFields.ExportOrderDate && order.StandardFields.ExportOrderDate.length > 0 &&
                            moment(order.StandardFields.ExportOrderDate).utc().format("DD.MM.YYYY HH:mm")
                          }

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal is-align-items-center field-print-hide">
                      <div className="field-label is-normal">
                        <label className="label">
                          {paymentStatusLabel}
                        </label>
                      </div>

                      { AccessToEditOrder ? (
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <span className="select">
                                <select
                                  name="ServiceData.Source.Status"
                                  ref={register}
                                  disabled
                                >
                                  {['failed', 'processing', 'pending', 'completed'].map((status) => (
                                    <option key={status} value={status}>
                                      {status}
                                    </option>
                                  ))}
                                </select>
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              {order.ServiceData.Source.Status}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="field is-horizontal is-align-items-center field-print-hide">
                      <div className="field-label is-normal">
                        <label className="label">
                          {deliveryStatusLabel}
                        </label>
                      </div>

                      { AccessToEditOrder ? (
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <span className="select">
                                <select
                                  name="StandardFields.DeliveryStatus"
                                  defaultValue={order.StandardFields.DeliveryStatus}
                                  ref={register}
                                  disabled
                                >
                                  {Object.keys(ORDER_STATUS).map((status) => (
                                    <option key={status} value={status}>
                                      {ORDER_STATUS[status]}
                                    </option>
                                  ))}
                                </select>
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              {ORDER_STATUS[order.StandardFields.DeliveryStatus]}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <hr />
                    
                    <div className="field is-horizontal field-print-hide">
                      <div className="field-label" />
                      <div className="field-body">
                        <div className="field">
                          <div className="field is-grouped">
                            <div className="control">
                              <ReactToPrint
                                bodyClass='stickers-component'
                                trigger={() => <span className="button is-block mb-3">{ printStickersLabel }</span>}
                                content={() => stickersPrintComponentRef.current}
                              />

                              <ReactToPrint
                                bodyClass='receipt-component'
                                trigger={() => <span className="button is-block mb-3">{ printReceiptLabel }</span>}
                                content={() => receiptPrintComponentRef.current}
                              />
                              { order.ShippingLine.ShipmentNumber && 
                                <>
                                  <span
                                    onClick={handleSaveShipmentLabelPDF}
                                    className={`button is-block mb-3 ${
                                      !loadedShipment && 'is-loading'
                                    }`}
                                  >
                                    <span>{saveShipmentLabelPDF}</span>
                                  </span>

                                  <span
                                    onClick={handlePrint}
                                    className={`button is-block mb-3 ${
                                      !loadedShipment && 'is-loading'
                                    }`}
                                  >
                                    <span>{printShipmentLabelPDF}</span>
                                  </span>
                                </>
                              }
                              {AccessToExportOrder && ['ready', 'delivered'].includes(order.StandardFields.DeliveryStatus) &&
                                <span
                                  onClick={handleExportOrder}
                                  className={`button is-block mb-3 ${
                                    loadingExportOrder && 'is-loading'
                                  }`}
                                >
                                  <span>{exportOrderLabel}</span>
                                </span>
                              }
                              {ShipmentMethodCode === 'LOCAL_PICKUP' && DeliveryStatus === 'ready' && 
                                <span
                                  onClick={handleSendReminder}
                                  className={`button is-block mb-3 ${
                                    loadingSendReminder && 'is-loading'
                                  }`}
                                >
                                  <span>{deliveryReminderLabel}</span>
                                </span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-account default" />
                    </span>
                    {billingInfoLabel}
                  </p>
                </header>
                <div className="card-content">
                  {Object.keys(order.Billing).sort().map((field) => (
                    <div key={field.toString()} className={classNames("field is-horizontal", {"field-print-hide": field === 'userId'})}>
                      <div className="field-label field-label-left is-normal">
                        <label className="label">
                          {field}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              type="text"
                              readOnly="readOnly"
                              className="input is-static"
                              name={`Billing.${ field }`}
                              ref={register}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="column preview">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-car default" />
                    </span>
                    {shippingInfoLabel}
                  </p>
                </header>
                <div className="card-content">
                  {Object.keys(order.Shipping).sort().map((field) => (
                    <div key={field.toString()} className="field is-horizontal">
                      <div className="field-label field-label-left is-normal">
                        <label className="label">
                          { field }
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              type="text"
                              readOnly="readOnly"
                              className="input is-static"
                              name={`Shipping.${ field }`}
                              ref={register}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    ))
                  }

                  {shipmentStatusData &&
                    <>
                      <div className="field is-horizontal">
                        <div className="field-label field-label-left is-normal">
                          <label className="label">
                            {TrackingNoLabel}
                          </label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                type="text"
                                readOnly="readOnly"
                                className="input is-static"
                                name='Shipping.TrackingNo'
                                value={shipmentStatusData.TrackingNo}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label field-label-left is-normal">
                          <label className="label">
                            {DeliveryConfirmedDateLabel}
                          </label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                type="text"
                                readOnly="readOnly"
                                className="input is-static"
                                name='Shipping.DeliveryConfirmedDate'
                                value={shipmentStatusData.DeliveryConfirmedDate}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div id="items-section" className="column">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-cart default" />
                    </span>
                    {orderItemsLabel}
                  </p>
                </header>
                <div className="card-content">
                  <div className="b-table">
                    {loading ? <ClipLoader /> : 
                      <OrderItems items={groupItems}/>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div id="notes-section" className="column">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-message default" />
                    </span>
                    {notesLabel}
                  </p>
                </header>
                <div className="card-content">
                  <p>{ orderNotes }</p>
                </div>
              </div>
            </div>
            <div id="order-summary-section" className="column">
              <div className="card tile is-child">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-credit-card default" />
                    </span>
                    {orderSummaryLabel}
                  </p>
                </header>
                <div className="card-content">
                  <table className="table is-fullwidth is-narrow">
                    <tbody>
                      {user.role !== 'Store' &&
                        <>
                          <tr>
                            <th><h3 className="title is-5">{`${shippingInfoLabel}: ${order.ShippingLine.Description}`}</h3></th>
                          </tr>
                          <tr>
                            <th>{subtotalLabel}</th>
                            <td>
                              <NumericFormat value={order.ShippingLine.Subtotal} displayType='text' thousandSeparator suffix={` ${getSymbolFromCurrency(order.StandardFields.Currency)}`} />
                            </td>
                          </tr>
                          <tr>
                            <th>{taxLabel}</th>
                            <td><NumericFormat value="0" displayType='text' thousandSeparator suffix={` ${getSymbolFromCurrency(order.StandardFields.Currency)}`} /></td>
                          </tr>
                          <tr>
                            <th><h3 className="title is-5">{ orderItemsLabel }</h3></th>
                          </tr>
                          <tr>
                            <th>{subtotalLabel}</th>
                            <td>
                              <NumericFormat value={order.StandardFields.Subtotal} displayType='text' thousandSeparator suffix={` ${getSymbolFromCurrency(order.StandardFields.Currency)}`} />
                            </td>
                          </tr>
                          <tr>
                            <th>{taxLabel}</th>
                            <td><NumericFormat value="0" displayType='text' thousandSeparator suffix={` ${getSymbolFromCurrency(order.StandardFields.Currency)}`} /></td>
                          </tr>
                        </>
                      }
                      <tr>
                        <th><h3 className="title is-4">{ TotalLabel }</h3></th>
                        <td>
                          <NumericFormat value={user.role === 'Store' ? totalForStore : order.StandardFields.GrandTotal} displayType='text' thousandSeparator suffix={` ${getSymbolFromCurrency(order.StandardFields.Currency)}`} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {accessToViewComments && 
          <div id="comments-section" className="column">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-message default" />
                  </span>
                  { commentsTitleSection }
                </p>
              </header>
              <div className={classNames("card-content", {'overlay': commentsDataChanging})}>
                
                { !commentsDataLoaded &&
                  <div className="has-text-centered">
                    <ClipLoader />
                  </div>
                }

                { orderComments && orderComments.length > 0 &&
                  <div ref={commentsBlock} className="comments-box pb-5">
                    { orderComments.map((comment) => (
                      <article id={`comment-${comment.id}`} key={comment.id} className={classNames('message',
                        {'is-primary': comment.primary}
                      )}>

                        <div className="message-body">
                          <p className={classNames('commeny-content')}>{ comment.content }</p>
                          <p className="is-size-7 mt-3">{moment(comment.createdAt).utc().format("MMMM DD, YYYY [at] h:mm:ssa")}{comment.authorName && ` by ${comment.authorName}`}</p>
                          {comment.email &&
                            <p className="is-size-7 mt-3">
                              { comment.email }
                            </p>
                          }
                          { (accessToDeleteAnyComments || (accessToDeleteOwnComments && comment.uid === user.id)) && 
                            <p className="is-size-7 mt-3"><span onClick={() => onDeleteComment(comment.id)} className="is-underlined is-clickable">delete</span></p>
                          }
                        </div>
                        
                      </article>
                    ))
                  }
                  </div>
                }
                
                {checkAccess("add new comment to order") &&
                  <>
                    <div className="field">
                      <div className="control">
                        <textarea ref={textareaRef} value={commentMsg} onChange={onChangeCommentMsg} className="textarea" placeholder="Add comment"/>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <button onClick={handleAddComment} className="button is-link">Add</button>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        }
      </div>
      <div className="is-hidden">
        <div ref={receiptPrintComponentRef}>
          <style>{getPageReceiptSize()}</style>
          <div className="has-text-centered">
            <div className="logo">
              <img
                src={logo}
                alt="Kringlan"
              />
            </div>
            <p className="mb-3">Takk fyrir að versla við Kringluna!</p>
            <h3 className="subtitle">Greiðslukvittun</h3>
            <p>{`Pöntun nr. ${order.ServiceData.Source.Identity}`}</p>
            <div className="has-text-left">
              <hr/>
              {Object.keys(groupItems).map(store => {
                return (
                  <div className="mb-2" key={store}>
                    <h3 className="has-text-weight-bold is-size-7">{store}</h3>
                    {groupItems[store].map(el => (
                      <p className="is-size-7 mb-1" key={`${el.SKU} - ${el.DeliveryStatus}`}>{`SKU: ${el.SKU} Nafn: ${el.Name} Magn: ${el.Qty} Verð: `}<NumericFormat value={ el.UnitPrice * el.Qty } displayType='text' thousandSeparator /></p>
                    ))}
                  </div>
                );
              })}
              <p className="is-size-7">{`Sendingarleið: ${order.ShippingLine.Description}`}</p>
              <hr/>
            </div>
            <div>
              <div className="is-flex is-justify-content-space-between mb-3">
                <h3 className="has-text-weight-bold is-size-7">Samtals</h3>
                <p className="has-text-weight-bold is-size-7"><NumericFormat value={order.StandardFields.GrandTotal} displayType='text' thousandSeparator suffix={` ${getSymbolFromCurrency(order.StandardFields.Currency)}`} /></p>
              </div>
              <p className="is-size-7 mb-1">Ef varan stenst ekki væntingar af einhverjum ástæðum er hægt að skipta/skila henni innan 14 daga.</p>
              <p className="is-size-7">Skil og skipti fara fram í þeirri verslun sem varan var keypt og er nóg að hafa kvittun meðferðis.</p>
            </div>
          </div>
        </div>

        { shipmentLabelData &&
          <div ref={shipmentLabelPrintComponentRef}>
            <style>{getPageStickersSize()}</style>
            <Document file={`data:application/pdf;base64,${shipmentLabelData}`}>
              <Page height={550} pageNumber={1} />
            </Document>
          </div>
        }

      </div>
    </>
  );
};

OrderForm.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};


export default OrderForm;
