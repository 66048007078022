/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { checkAccess } from 'permissions';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { settingsCleanUp, getGiftcardToken } from 'state/actions/settings';
import { useFormatMessage } from 'hooks';
import ErrorMessage from 'components/ErrorMessage';
import classNames from 'classnames';

const SettingsForm = ({ settings, onSubmitHandler, schema}) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.settings.loading,
      success: state.settings.success,
    }),
    shallowEqual
  );

  const [getToken, setGetToken] = useState(false);

  const dispatch = useDispatch();

  const defaultValues = [];

  if(settings && settings.length > 0){
    settings.forEach(settingItem => {
      defaultValues[settingItem.id] = settingItem.value;
    });
  };

  const { register, handleSubmit, errors, setValue, watch, setError, clearErrors } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {giftcardEmail, giftcardPassword} = watch(["giftcardEmail", "giftcardPassword"]);

  useEffect(() => {
    return () => dispatch(settingsCleanUp());
  }, [dispatch, success, setValue]);

  const AccessToEditSettings = checkAccess('edit settings');

  const getTokenHandler = async() => {
    setGetToken(true);
    clearErrors(['giftcardEmail', 'giftcardPassword']);
    let token = '';

    const validEmail = await yup.string().required().email('must be a valid email').isValid(giftcardEmail);

    if(!validEmail){
      setError('giftcardEmail', {type: "manual", message: "Error"});
    }

    if(!giftcardPassword){
      setError('giftcardPassword', {type: "manual", message: "Required Field"});
    }

    if(giftcardEmail && giftcardPassword && validEmail){
      token = await getGiftcardToken(giftcardEmail, giftcardPassword);
      if(token){
        setValue('giftcardToken', token);
      }
    }
    setGetToken(false);
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <div className="card-content">
              {settings && settings.length > 0 &&
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="mb-6">
                    <h3 className="title is-6">{useFormatMessage(`Settings.saltpay` )}</h3>
                  
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.merchantID` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="merchantID"
                              id="merchantID"
                              className={classNames('input', {
                                'is-danger': errors.merchantID,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                          {errors.merchantID && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.publicKey` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="publicKey"
                              id="publicKey"
                              className={classNames('input', {
                                'is-danger': errors.publicKey,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                          {errors.publicKey && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.privateKey` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="privateKey"
                              id="privateKey"
                              className={classNames('input', {
                                'is-danger': errors.privateKey,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                          {errors.privateKey && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-6">
                    <h3 className="title is-6">{useFormatMessage(`Settings.giftcard` )}</h3>
                  
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.giftcardEmail` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="giftcardEmail"
                              id="giftcardEmail"
                              className={classNames('input', {
                                'is-danger': errors.giftcardEmail,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                          {errors.giftcardEmail && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.giftcardPassword` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="giftcardPassword"
                              id="giftcardPassword"
                              className={classNames('input', {
                                'is-danger': errors.giftcardPassword,
                              })}
                              ref={register}
                              type="password"
                            />
                          </div>
                          {errors.giftcardPassword && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.giftcardToken` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              readOnly="readOnly"
                              name="giftcardToken"
                              id="giftcardToken"
                              className={classNames('input', {
                                'is-danger': errors.giftcardToken,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                          {errors.giftcardToken && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                        <span 
                          onClick={getTokenHandler} 
                          className={`button ml-1 ${
                            getToken && 'is-loading'
                          }`}
                        >
                          {useFormatMessage(`Settings.giftcardGetToken`)}
                        </span>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.giftcardHoldInterval` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="giftcardHoldInterval"
                              id="giftcardHoldInterval"
                              className={classNames('input', {
                                'is-danger': errors.giftcardHoldInterval,
                              })}
                              ref={register}
                              type="number"
                            />
                          </div>
                          {errors.giftcardHoldInterval && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-6">
                    <h3 className="title is-6">{useFormatMessage(`Settings.fees` )}</h3>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.serviceFee` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="serviceFee"
                              id="serviceFee"
                              className={classNames('input', {
                                'is-danger': errors.serviceFee,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                          {errors.serviceFee && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.paymentFee` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="paymentFee"
                              id="paymentFee"
                              className={classNames('input', {
                                'is-danger': errors.paymentFee,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                          {errors.paymentFee && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.shippingFee` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="shippingFee"
                              id="shippingFee"
                              className={classNames('input', {
                                'is-danger': errors.shippingFee,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                          {errors.shippingFee && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                  </div>

                  <div>
                    <h3 className="title is-6">{useFormatMessage(`Settings.other` )}</h3>
                  
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.kringlanSSN` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="kringlanSSN"
                              id="kringlanSSN"
                              className={classNames('input', {
                                'is-danger': errors.kringlanSSN,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                          {errors.kringlanSSN && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.pickupOrderReminderDelay` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="pickupOrderReminderDelay"
                              id="pickupOrderReminderDelay"
                              className={classNames('input', {
                                'is-danger': errors.pickupOrderReminderDelay,
                              })}
                              ref={register}
                              type="number"
                            />
                          </div>
                          {errors.pickupOrderReminderDelay && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.orderCancelledDelay` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="orderCancelledDelay"
                              id="orderCancelledDelay"
                              className={classNames('input', {
                                'is-danger': errors.orderCancelledDelay,
                              })}
                              ref={register}
                              type="number"
                            />
                          </div>
                          {errors.orderCancelledDelay && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(`Settings.cancelOldOrderDelay` )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="cancelOldOrderDelay"
                              id="cancelOldOrderDelay"
                              className={classNames('input', {
                                'is-danger': errors.cancelOldOrderDelay,
                              })}
                              ref={register}
                              type="number"
                            />
                          </div>
                          {errors.cancelOldOrderDelay && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  { AccessToEditSettings && 
                    <div className="field is-horizontal">
                      <div className="field-label" />
                      <div className="field-body">
                        <div className="field">
                          <div className="field is-grouped">
                            <div className="control">
                              <button
                                type="submit"
                                disabled={getToken}
                                className={`button blue-button ${
                                  loading && 'is-loading'
                                }`}
                              >
                                <span>{useFormatMessage('Settings.submit')}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsForm;